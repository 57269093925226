.__filter-dropdown {
  --bg-color: white;
  
  [data-theme=dark] & {
    --bg-color: #212529;
  }

  .dropdown-menu {
    border: none;
    background-color: transparent;

    .transition-container {
      padding: 24px;
      border-radius: 8px;
      border: 1px solid #ECECEC;
      box-shadow: 0px 6px 12px 0px rgba(0, 0, 0, 0.08);
      animation: __filter-show-menu 0.2s forwards;
      background-color: var(--bg-color);
    }

    .field-match {
      display: flex;
      gap: 20px;
      margin-bottom: 16px;

      width: 280px;

      .field {
        flex: 5;
        color: #525252;
        border-color: #DADADA;
        border-radius: 4px;
        padding: 6px 8px;
        font-size: 15px;
      }

      .match {
        flex: 3;
        color: #525252;
        background-color: #F3F3F3;
        border-color: transparent;
        padding: 6px 8px;
        font-size: 15px;
        border-radius: 4px;
      }
    }

    .input-value {
      color: #525252;
      border-radius: 4px;
      padding: 6px 8px;
      font-size: 15px;

      &::placeholder {
        font-style: italic;
        color: #9D9898;
      }
      
      &:not(:focus) {
        border-color: #DADADA;
      }
    }

    p.explanation {
      color: #9D9898;
      text-align: center;
      font-size: 14px;
      font-style: italic;
      line-height: 22px;
      margin: 12px;
      padding: 4px 0;
      
      .value {
        font-weight: 700;
        color: #7A7A7A;
      }
    }

    .actions {
      display: flex;
      justify-content: space-between;
      margin-top: 16px;
    }

    button.close {
      all: unset;
      box-sizing: border-box;
      cursor: pointer;
      color: #706F6F;
      font-weight: 600;
      padding: 8px 20px;
      border-radius: 32px;
      font-size: 15px;

      &:hover {
        // background-color: #F3F3F3;
        color: #333333;
      }
    }

    button[type=submit] {
      all: unset;
      box-sizing: border-box;
      cursor: pointer;
      background-color: #4C62FB;
      color: white;
      border-radius: 32px;
      padding: 8px 20px;
      font-weight: bold;
      transition: 0.3s background-color;
      font-size: 15px;
  
      &:disabled {
        opacity: 0.7;
        cursor: initial;
      }
    }
  }
}

@keyframes __filter-show-menu {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }

  to {
    opacity: 1;
    // from bootstrap: translate3d(2px, 32px, 0px);
    transform: translateY(0px);
  }
}

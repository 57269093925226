.__export-modal {

  &.__modal .modal-body {
    color: #5F5F5F;
  }
  
  .spinner {
    color: #D9D9D9;
    margin-bottom: 20px;
    
    .spinner-border {
      --bs-spinner-width: 60px;
      --bs-spinner-height: 60px;
      --bs-spinner-border-width: 5px;
      --bs-spinner-animation-speed: 1.25s;
    }
  }

  .checkmark {
    margin-bottom: 20px;

    > svg {
      width: 60px;
      height: auto;

      path {
        fill: #0BA536;
      }
    }
  }

  h2.failed {
    margin-bottom: 32px;
  }

  .export-ready {
    margin-bottom: 32px;
  }
  
  .modal {
    &-body {
      text-align: center;
    }

    &-dialog {
      max-width: 420px;
    }
  }
}

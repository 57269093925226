.__lead-status-manager {
  max-width: 880px;

  .title-actions {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;

    h2 {
      margin-bottom: 0;
    }

    button.main-action {
      all: unset;
      box-sizing: border-box;
      cursor: pointer;
      user-select: none;
      display: flex;
      background-color: #F1F1F1;
      color: #797979;
      border-radius: 32px;
      padding: 6px 20px;
      transition: 0.3s background-color;
      font-size: 15px;
      align-items: center;
      gap: 8px;
    }
  }

  .statuses-table {
    margin-bottom: 32px;

    .table-head {
      display: flex;
      padding: 12px 8px;
      gap: 12px;
      background-color: #F7F7FA;
      font-weight: bold;
      color: #5C5C5C;
      font-size: 14px;
      border-radius: 8px;
      margin-bottom: 4px;
    }

    .sortable-item {
      &:hover {
        .__editable-field {
          button.edit {
            opacity: 1;
          }

          .empty-field-add {
            opacity: 1;
          }
        }

        .delete-btn {
          visibility: visible;
        }

        .enter-description {
          visibility: visible;
        }
      }

      .row-description {
        font-style: italic;
      }
    }

    .row {
      &-handle {
        flex: 32px 0;
      }

      &-name {
        width: 25%;
      }

      &-color {
        flex: 48px 0;
        text-align: center;
      }

      &-description {
        flex: 3;
        min-width: 0;

        .enter-description {
          visibility: hidden;
          color: #8A8A8A;
        }
      }

      &-delete {
        flex: 112px 0;
      }
    }

    .delete-btn {
      all: unset;
      display: flex;
      gap: 6px;
      box-sizing: border-box;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 6px 16px;
      border-radius: 32px;
      visibility: hidden;
      color: #FE0707;

      &:hover {
        background-color: rgba(#FE0707, 0.07);
        // color: #bb0000;
        // text-decoration: underline;
      }
      
      > svg {
        width: 20px;
        height: auto;
        fill: currentColor;
      }
    }
  }
}

.__custom-fields-manager {
  max-width: 880px;
  margin-top: 60px;

  --table-color     : #5C5C5C;
  --table-bg        : #F7F7FA;
  --table-border    : #F7F7FA;
  --add-bg          : #FBFBFB;
  --add-border      : #F2F2F6;
  --action-color    : #797979;
  --action-bg       : #F1F1F1;
  --action-hover-bg : #e3e2e2;
  --action-border   : #F1F1F1;
  --delete-color    : #FE0707;
  --cancel-hover-bg : #F1F1F1;
  --error-text      : #D10000;
  --submit-bg       : #4C62FB;
  --submit-hover-bg : #3b55ff;
  --empty-border    : #AAAAB2;
  
  [data-theme=dark] & {
    --table-border    : #818990;
    --table-color     : white;
    --table-bg        : #1b1c1d;
    --add-bg          : #25282b;
    --add-border      : #818990;
    --action-color    : #EEEEEE;
    --action-bg       : transparent;
    --action-hover-bg : #1b1c1d;
    --action-border   : #C4C8CC;
    --delete-color    : #fc6565;
    --cancel-hover-bg : #3c3f42;
    --error-text      : #fc6565;
    --submit-bg       : #2E47F3;
    --submit-hover-bg : #3b55ff;
    --empty-border    : #3c3f42;
  }

  .title-actions {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;

    h2 {
      margin-bottom: 0;
    }

    button.main-action {
      all: unset;
      box-sizing: border-box;
      cursor: pointer;
      user-select: none;
      display: flex;
      background-color: var(--action-bg);
      border: 1px solid var(--action-border);
      color: var(--action-color);
      border-radius: 32px;
      padding: 6px 20px;
      transition: 0.3s background-color;
      font-size: 15px;
      align-items: center;
      gap: 8px;

      &:hover {
        background-color: var(--action-hover-bg);
      }
    }
  }

  .empty-state {
    border: 1px dashed var(--empty-border);
    border-radius: 4px;
    padding-block: 40px;
    text-align: center;
    font-style: italic;
    color: var(--action-color);
    font-size: 16px;
    line-height: 24px;

    a {
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .fields-table {
    margin-bottom: 32px;

    .table-head {
      display: flex;
      padding: 12px 8px;
      gap: 12px;
      background-color: var(--table-bg);
      font-weight: bold;
      color: var(--table-color);
      border: 1px solid var(--table-border);

      font-size: 14px;
      border-radius: 8px;
      margin-bottom: 4px;
    }

    .sortable-item {
      .row-code {
        .__editable-field {
          .field, input {
            font-family: var(--font-monospace);
            font-size: 14px;  
            line-height: 18px;
          }
        }
      }

      &:hover {
        .__editable-field {
          button.edit {
            opacity: 1;
          }

          .empty-field-add {
            opacity: 1;
          }
        }

        .delete-btn {
          visibility: visible;
        }

        .enter-description {
          visibility: visible;
        }
      }
    }

    .row {
      &-handle {
        flex: 32px 0;
      }

      &-code {
        width: 30%;
      }

      &-label {
        flex: 2;
        min-width: 0;
      }

      &-delete {
        flex: 112px 0;
      }
    }

    .delete-btn {
      all: unset;
      display: flex;
      gap: 6px;
      box-sizing: border-box;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 6px 16px;
      border-radius: 32px;
      visibility: hidden;
      color: var(--delete-color);

      &:hover {
        background-color: rgba(#FE0707, 0.07);
      }
      
      > svg {
        width: 20px;
        height: auto;
        fill: currentColor;
      }
    }
  }
  

  .add-field {
    background-color: var(--add-bg);
    border: 1px solid var(--add-border);
    border-radius: 12px;
    padding: 20px;
    margin: 20px 0;
    font-size: 15px;

    .form-label {
      margin-bottom: 0;
      margin-right: 8px;
    }

    .field-container {
      display: flex;
      gap: 8px;
    }

    .field-container {
      display: flex;
      gap: 20px;
      align-items: center;

      &.code {
        input {
          font-family: var(--font-monospace);
        }
      }

      &:first-of-type {
        margin-bottom: 16px;
      }

      .form-group {
        width: 240px;
      }

      label {
        min-width: 40px;
      }
      
      .code-description {
        width: 400px;
        font-style: italic;
        color: var(--table-color);
      }

      .code {
        display: flex;
        align-items: center;
        gap: 8px;
        flex: 1;
        flex-wrap: wrap;
      }

      .description {
        display: flex;
        align-items: center;
        gap: 8px;
        flex: 2;
        flex-wrap: wrap;
      }

      .form-control {
        font-size: 15px;
      }
    }

    .actions {
      display: flex;
      gap: 8px;
      margin-left: auto;

      button {
        all: unset;
        box-sizing: border-box;
        cursor: pointer;
        user-select: none;
        border-radius: 32px;
        padding: 6px 20px;
        font-size: 15px;
        min-width: 90px;
        display: flex;
        align-items: center;
        justify-content: center;
        
        &.cancel {
          color: var(--action-color);

          &:hover {
            background-color: var(--cancel-hover-bg);
          }
        }
        
        &[type=submit] {
          background-color: var(--submit-bg);
          transition: 0.3s background-color;
          font-weight: bold;
          color: white;

          &:hover {
            background-color: var(--submit-hover-bg);
          }

          &:disabled {
            opacity: 0.7;
            cursor: initial;
          }
        }
      }
    }

    .request-error {
      margin-top: 24px;
      font-size: 14px;
      color: var(--error-text);
      text-align: center;
    }
  }
}

.__sortable {
  display: flex;
  flex-direction: column;
  padding: 0;
  list-style: none;

  --bg-color        : white;
  --hover-bg        : #FBFBFB;
  --border-color    : #F2F2F6;
  --highlight-color : #FFEBA3;
  --text-color      : #5F5F5F;

  [data-theme=dark] & {
    --bg-color        : #212529;
    --hover-bg        : #2f3236;
    --border-color    : #3f3f40;
    --highlight-color : #c6a126;
    --text-color      : #DDDEE2;
  }

  &-dragging-item {
    box-shadow: 0 0 0 calc(1px / var(--scale-x, 1)) rgba(63, 63, 68, 0.05), 0 1px calc(3px / var(--scale-x, 1)) 0 rgba(34, 33, 81, 0.15);
    background-color: var(--bg-color);

    // no access because the dragging item is not a child of .__sortable
    --bg-color: white;

    [data-theme=dark] & {
      box-shadow: 0 0 0 calc(1px / var(--scale-x, 1)) rgba(63, 63, 68, 0.05), 0 1px calc(5px / var(--scale-x, 1)) 0 rgba(white, 0.15);
      --bg-color: #212529;
    }
    
    li {
      list-style: none;
    }
  }
  
  &-column-view {
    display: flex;
    justify-content: space-between;
    flex-grow: 1;
    gap: 12px;
    align-items: center;
    padding: 8px 8px;
    background-color: var(--bg-color);
    list-style: none;
    font-size: 15px;
    min-height: 56px;
    color: var(--text-color);

    &.newly-created {
      animation: --sortable-item-created 2s forwards;
    }
  
    &:hover {
      background-color: var(--hover-bg);
    }
  
    &:not(:last-of-type) {
      border-bottom: 1px solid var(--border-color);
    }
  }
  
  &-drag-handle {
    display: flex;
    width: 20px;
    padding: 8px 12px;
    align-items: center;
    justify-content: center;
    flex: 0 0 auto;
    touch-action: none;
    cursor: var(--cursor, pointer);
    border-radius: 6px;
    border: none;
    outline: none;
    appearance: none;
    background-color: transparent;
    -webkit-tap-highlight-color: transparent;
  
    &:hover {
      background-color: rgba(0, 0, 0, 0.05);
    }
  
    &:focus-visible {
      box-shadow: 0 0px 0px 2px #4c9ffe;
    }
  
    svg {
      flex: 0 0 auto;
      margin: auto;
      height: 100%;
      overflow: visible;
      fill: #919eab;
    }
  }
}

@keyframes --sortable-item-created {
  from {
    background-color: inherit;
  }

  20% {
    background-color: var(--highlight-color);
  }

  80% {
    background-color: var(--highlight-color);
  }

  to {
    background-color: inherit;
  }
}

.__lead-distribution-modal {
  --table-color     : #5C5C5C;
  --table-bg        : #F7F7FA;
  --table-border    : #F7F7FA;
  --error-text      : #D10000;
  --table-row       : #f2f2f6;
  
  [data-theme=dark] & {
    --table-border    : #818990;
    --table-color     : white;
    --table-bg        : #1b1c1d;
    --error-text      : #fc6565;
    --table-row       : #3f3f40;
  }

  .modal {
    &-dialog {
      max-width: 680px;
    }
  }

  .modal-content {
    max-height: 90vh;
  }

  .modal-body {
    overflow: auto;
  }

  p.intro {
    color: #5F5F5F;
    font-style: italic;
    font-size: 15px;
  }

  .no-users {
    border: 1px dashed #E6E6E6;
    border-radius: 4px;
    text-align: center;
    padding: 32px 8px;
    font-style: italic;
    color: #5F5F5F;
    font-size: 15px;

    a {
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  table {
    width: 100%;
    table-layout: fixed;
    margin-block: 28px;

    thead {
      border-radius: 8px;
      font-size: 14px;
      
      tr { 
        color: var(--table-color);
        
        th {
          padding: 8px;
          background-color: var(--table-bg);
          
          &:first-of-type {
            border-top-left-radius: 8px;
            border-bottom-left-radius: 8px;
            padding-left: 24px;
          }

          &:last-of-type {
            border-top-right-radius: 8px;
            border-bottom-right-radius: 8px;
          }
        }
      }
    }

    tbody {
      font-size: 15px;

      tr {
        &:not(:last-of-type) {
          border-bottom: 1px solid var(--table-row);
        }

        td {
          padding: 8px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;

          &:first-of-type {
            padding-left: 16px;
          }

          &.user {
            font-size: 14px;
          }
          
          &.error {
            font-size: 14px;
            color: var(--error-text);
          }
        }
      }
    }

    .percentage-input {
      display: flex;
      align-items: center;
      gap: 6px;
      font-size: 15px;
      
      input {
        font-size: 15px;
        text-align: right;
        padding: 4px 6px;
      }

      &.input-error {
        color: var(--error-text);

        input {
          border-color: var(--bs-form-invalid-border-color);
          box-shadow: 0 0 0 .25rem rgba(var(--bs-danger-rgb),.25);      
        }
      }
    }

    .user {
      width: 34%;
    }

    .distribution {
      width: 14%;
    }

    .description {
      width: 42%;
    }
  }

  .remaining-percentage {
    min-height: 28px;
    display: flex;
    justify-content: flex-end;
    align-items: baseline;

    .number {
      font-weight: bold;
      font-size: 17px;
      display: inline-block;
      text-align: right;
      margin-left: 6px;
    }

    .error {
      font-style: italic;
      color: var(--error-text);
    }
  }
}

.__reset-password {
  padding: 20px;
  min-height: 100vh;
  min-height: 100dvh;
  background-color: #F5F5F5;
  display: flex;
  align-items: center;

  h1 {
    text-transform: uppercase;
    color: #4C62FB;
    text-align: center;
    position: absolute;
    top: 24px;
    font-weight: 600;
    font-size: 20px;
    left: 50%;
    transform: translateX(-50%);
    font-family: 'Montserrat', sans-serif;
    white-space: nowrap;
  }

  form {
    margin: 0 auto;
    max-width: 100%;
    width: 380px;
    padding: 32px;
    border-radius: 16px;
    background-color: white;
    box-shadow: 0px 4px 12px 0px rgba(black, 0.1);
    color: #3A3A3A;  

    h2 {
      font-size: 32px;
      text-align: center;
      margin-bottom: 32px;
      margin-top: 8px;
      font-family: 'Montserrat', sans-serif;
    }
  
    .field {
      margin: 12px 0;
    }
  
    .name-inputs {
      display: flex;
      gap: 8px;
      margin: 12px 0;
  
      .field {
        margin: 0;
      }
    }
  
    input {
      border-radius: 8px;
      padding: 8px 12px;
    }
  
    .action {
      text-align: center;
      font-size: 14px;
    }
    
    .success-icon {
      width: 60px;
      height: 60px;
      margin: 12px auto 20px;
      
      path {
        fill: #0BA536;
      }
    }
  
    .request-error {
      color: #D10000;
      margin-bottom: 12px;
    }

    .request-success {
      color: #0BA536;
      margin-bottom: 12px;
      font-weight: bold;
      font-size: 16px;
    }
  
    p { 
      font-size: 14px;
      text-align: center;
  
      &.create-account {
        margin-bottom: 0;
      }
  
      &.visible {
        visibility: visible;
      }
    }
  
    a {
      color: #1C72D7;
      text-decoration: none;
  
      &:hover {
        text-decoration: underline;
      }
    }
  
    button[type=submit] {
      all: unset;
      box-sizing: border-box;
      cursor: pointer;
      background-color: #4C62FB;
      color: white;
      border-radius: 32px;
      padding: 8px 20px;
      min-width: 220px;
      font-weight: bold;
      transition: 0.3s background-color;
      font-size: 18px;
      margin: 8px auto;
  
      .spinner-border {
        --bs-spinner-border-width: 0.15em;
      }
  
      &:disabled {
        opacity: 0.7;
        cursor: initial;
      }
  
      &:hover {
        transition: 0.3s background-color;
        background-color: #3b55ff;
      }
  
      &:focus {
        transition: 0.15s outline;
        outline: solid 4px rgba(blue, 0.2);
      }
    }
  }
}

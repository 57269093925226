.__login-signup {
  padding: 20px;
  min-height: 100vh;
  min-height: 100dvh;
  background-color: #F5F5F5;
  display: flex;
  align-items: center;

  h1 {
    text-transform: uppercase;
    color: #4C62FB;
    text-align: center;
    position: absolute;
    top: 24px;
    font-weight: 600;
    font-size: 20px;
    left: 50%;
    transform: translateX(-50%);
    font-family: 'Montserrat', sans-serif;
    white-space: nowrap;
  }

  .form-language {
    margin: 0 auto;
    max-width: 100%;
    width: 380px;
  }

  .language-select {
    color: #3A3A3A;
    margin-top: 40px;
    display: flex;
    gap: 20px;
    align-items: center;
    justify-content: center;

    .form-label {
      margin-bottom: 0;
    }

    .form-select {
      width: unset;
      color: #4A4A4A;
      min-width: 140px;
      border-radius: 8px;
      border-color: #E6E6E6;
      box-shadow: 0px 2px 6px 0px rgba(black, 0.05);
    }
  }
}

.__filter-bar {

  --bg-color: #F7F7FA;
  --border-color: #E0E0E3;
  --selected-bg: white;

  [data-theme=dark] & {
    --bg-color: #1b1c1d;
    --border-color: #414144;
    --selected-bg: #212529;
  }

  background-color: var(--bg-color);
  border-block: 1px solid var(--border-color);
  margin: 20px 0;
  z-index: 3;
  position: relative;

  @media screen and ((max-width: 1440px) or (max-height: 920px)) {
    margin-block: 8px;
  }

  &-remove-from-top {
    z-index: 1;
  }

  .content {
    margin: 0 auto;
    padding: 20px 40px;
    animation: __filter-bar-show 0.2s forwards;
    opacity: 0;
    display: flex;
    justify-content: space-between;
  }

  .filters {
    display: flex;
    gap: 12px;
    align-items: stretch;

    .and {
      align-self: center;
      color: #807E7E;
    }
  }

  .clear-filter {
    all: unset;
    box-sizing: border-box;
    cursor: pointer;
    color: #1C72D7;
    font-size: 15px;
    font-style: italic;
    font-weight: 400;
    line-height: normal;
    user-select: none;

    &:hover {
      text-decoration: underline;
      color: #3f92f3;
    }
  }

  .selected-filter {
    background-color: var(--selected-bg);
    padding: 4px;
    display: flex;
    justify-content: space-between;
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.05);
    border-radius: 4px;

    button.remove-filter {
      all: unset;
      box-sizing: border-box;
      padding: 4px 10px;
      cursor: pointer;
      border-radius: 2px;
      color: #828284;

      &:hover {
        background-color: #F5F5FA;
      }
    }

    button.edit-filter {
      all: unset;
      cursor: pointer;
      padding: 6px 8px;
      border-radius: 4px;
      font-size: 14px;
      color: #414141;

      &:hover {
        background-color: #F5F5FA;
      }

      .field {
        font-weight: 600;
      }

      .match {
        color: #858585;
        display: inline-block;
        margin-inline: 2px;
      }
      
      .search {
        font-weight: 700;
        font-style: italic;
      }
    }
  }

  button.add-filter {
    all: unset;
    box-sizing: border-box;
    cursor: pointer;
    border: 1px dashed #B3B3C9;
    height: 40px;
    width: 40px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    background-color: #F5F5FA;
    color: #78789A;
    font-size: 20px;
    border-radius: 4px;

    &:hover {
      background-color: #EFEFEF;
      transition: 0.2s background-color;
    }
  }
}

@keyframes __filter-bar-show {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
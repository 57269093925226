.__confirmation-modal {
  &.__confirmation-modal-dont-show {
    .modal {
      &-dialog {
        max-width: 520px;
      }
    }
  }

  .modal {
    &-dialog {
      max-width: 420px;
    }

    &-footer {
      .form-check {
        flex: 1;
        color: #5a5a5a;
      }
    }
  }
}

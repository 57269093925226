.__button {
  all: unset;
  box-sizing: border-box;
  cursor: pointer;

  --primary-btn-bg   : #4C62FB;
  --secondary-btn-bg : #F1F1F1;
  --link-btn-color   : #797979;

  [data-theme=dark] & {
    --primary-btn-bg   : #2e47f3;
    --secondary-btn-bg : #313131;
    --link-btn-color   : #b9b9b9;
  }

  &-primary {
    background-color: var(--primary-btn-bg);
    transition: 0.3s background-color;
    font-weight: bold;
    color: white;
    border-radius: 32px;
    padding: 10px 24px;

    &:hover {
      background-color: #3b55ff;
    }

    &:disabled {
      opacity: 0.7;
      cursor: initial;
    }

    &:focus {
      transition: 0.15s outline;
      outline: solid 4px rgba(blue, 0.2);
    }
  }

  &-link {
    padding: 10px 24px;
    color: var(--link-btn-color);
  }
}
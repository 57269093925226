.__auth-form {
  padding: 32px;
  border-radius: 16px;
  background-color: white;
  box-shadow: 0px 4px 12px 0px rgba(black, 0.1);
  color: #3A3A3A;

  h2 {
    font-size: 32px;
    text-align: center;
    margin-bottom: 24px;
    margin-top: 8px;
    font-family: 'Montserrat', sans-serif;
  }

  .field {
    margin: 12px 0;
  }

  .name-inputs {
    display: flex;
    gap: 8px;
    margin: 12px 0;

    .field {
      margin: 0;
    }
  }

  .dev-accounts {
    user-select: none;
    position: absolute;
    bottom: 16px;
    right: 50%;
    transform: translateX(50%);
    font-size: 12px;
    font-weight: bold;
    text-transform: uppercase;
    color: #1C72D7;
    
    &:hover {
      filter: contrast(1.2);
      text-decoration: underline;
    }
  }

  input {
    border-radius: 8px;
    padding: 8px 12px;
  }

  .action {
    text-align: center;
    font-size: 14px;
  }

  .request-error {
    color: #D10000;
    margin-bottom: 12px;
  }

  p { 
    font-size: 14px;
    text-align: center;

    &.create-account {
      margin-bottom: 0;
    }

    &.visible {
      visibility: visible;
    }
  }

  a {
    color: #1C72D7;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  button[type=submit] {
    all: unset;
    box-sizing: border-box;
    cursor: pointer;
    background-color: #4C62FB;
    color: white;
    border-radius: 32px;
    padding: 8px 20px;
    min-width: 120px;
    font-weight: bold;
    transition: 0.3s background-color;
    font-size: 18px;
    margin: 8px auto;

    .spinner-border {
      --bs-spinner-border-width: 0.15em;
    }

    &:disabled {
      opacity: 0.7;
      cursor: initial;
    }

    &:hover {
      transition: 0.3s background-color;
      background-color: #3b55ff;
    }

    &:focus {
      transition: 0.15s outline;
      outline: solid 4px rgba(blue, 0.2);
    }
  }

  button.google-sso {
    all: unset;
    box-sizing: border-box;
    cursor: pointer;
    border: 1px solid #dee2e6;
    display: flex;
    align-items: center;
    gap: 12px;
    padding: 8px;
    width: 100%;
    justify-content: center;
    border-radius: 8px;
    transition: 0.3s background-color;

    &:hover {
      background-color: #f8f8f8;
    }

    > svg {
      width: 20px;
      height: auto;
    }
  }
}

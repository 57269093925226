.__dropdown {
  --bg-color: white;
  --active-bg: #E4E7FF;
  --active-color: #4C62FB;
  --click-bg: #EEEEEE;
  --text-color: #5C5C5C;
  --menu-border: #E5E5E5;

  [data-theme=dark] & {
    --bg-color: #373a3d;
    --active-bg: #2e47f3;
    --active-color: #E4E7FF;  
    --text-color: #dddee2;
    --menu-border: #818a92;
    --click-bg: #3e3e3e;
  }

  .dropdown-toggle {
    all: unset;
    box-sizing: border-box;
    cursor: pointer;
    display: block;
    width: 100%;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 13px;
    
    border: 1px solid transparent;
    border-radius: 4px;
    white-space: nowrap;
  
    &::after {
      content: none;
    }
  
    &:hover, &.show {
      background-color: var(--bg-color);
      box-shadow: 0px 0px 4px 0px rgba(black, 0.05);

      svg.chevron {
        opacity: 1;
        transform: rotate(180deg);
      }
    }

    .selected {
      font-weight: bold;
      color: #4C62FB;

      [data-theme=dark] & {
        color: #70b4ff;
      }
    }
  
    .label {
      overflow: hidden;
      text-overflow: ellipsis;
      flex: 1; 
    }
  
    svg.chevron {
      width: 12px;
      height: 12px;
      fill: #888888;
      opacity: 0;
      margin-top: 4px;
      transform: rotate(180deg) translateY(-50%);
  
      transition-duration: 0.2s;
      transition-property: transform, opacity;
    }
  }

  .dropdown-menu {
    border-color: var(--menu-border);
    border-radius: 8px;
    padding: 8px;

    .dropdown-item {
      color: var(--text-color);
      padding: 6px 8px;
      margin-block: 2px;
      border-radius: 4px;

      &:active {
        background-color: var(--click-bg);
      }

      &.active {
        background-color: var(--active-bg);
        color: var(--active-color);
      }
    }
  }
}

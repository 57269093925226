.__import-leads-modal {
  &.__modal {
    --text-color: #5F5F5F;
    --text-light: #9D9DB1;
    --close-btn: #8A8A8A;
    --link-color: #469BFF;
    --import-border: #AAAAB2;
    --primary-color: #4C62FB;
    --upload-hover-bg: #F4F4F4;
    --table-head-bg: #F7F7FA;
    --table-row-border: #F2F2F6;
    --item-active-color: #e4e9ee;
  }


  &-dragging {
    .modal-content {
      * {
        pointer-events: none;
      }
    }
  }

  .description {
    color: var(--text-color);
    font-style: italic;
  }

  a {
    color: var(--link-color);
  }

  .modal-dialog {
    width: 652px;
  }
  
  .modal-content {
    min-height: 532px;
  }

  .step-upload {
    display: flex;
    flex-direction: column;
  }

  input[type=file] {
    display: none;
  }

  .file-box {
    border: 1px dashed var(--import-border);
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 32px;
    background-color: var(--upload-hover-bg);
    flex: 1;
    border-radius: 4px;
    padding: 16px;

    .file-info {
      display: flex;
      gap: 8px;
      box-shadow: 0 1px 6px 0 rgba(black, 0.15);
      align-items: center;
      background-color: white;
      padding: 16px 12px;
      width: 100%;
      border-radius: 6px;

      .file-name-size {
        flex: 1;
        .file-name {
          font-weight: bold;
        }

        .file-size {
          font-size: 15px;
          color: var(--text-light);
        }
      }
    }

    .file-icon {
      width: 48px;
      height: auto;
    }

    .delete-file {
      all: unset;
      cursor: pointer;
      box-sizing: border-box;
      color: var(--close-btn);
      padding: 4px 10px;
      border-radius: 8px;
      font-size: 18px;

      &:hover {
        background-color: var(--upload-hover-bg);
      }
    }
  }

  .upload-file {
    display: flex;
    flex: 1;
    flex-direction: column;
  }

  .upload-box {
    border: 1px dashed var(--import-border);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    text-align: center;
    cursor: pointer;
    transition: 0.3s background-color;
    flex-direction: column;
    margin-top: 32px;
    flex: 1;
    user-select: none;
    
    > * {
      cursor: pointer;
    }

    &:hover, &.dragging {
      background-color: var(--upload-hover-bg);

      svg.upload-icon {
        transform: translateY(-3px);
      }
    }

    h3 {
      color: var(--primary-color);
      font-size: 20px;
      font-weight: bold;
    }

    svg.upload-icon {
      fill: currentColor;
      color: var(--primary-color);
      width: 80px;
      height: auto;
      margin-block: 12px 24px;
      transition: 0.3s transform;
    }
  }

  .header-container {
    background-color: var(--upload-hover-bg);
    padding: 12px;
    overflow: auto;
    border-radius: 4px;
    max-height: 50vh;
    margin-block: 32px;
  }

  .header-table {
    background-color: white;
    font-size: 13px;

    tr {
      &.selected {
        font-weight: bold;

        td {
          background-color: #a5ceff;
          border-color: #7db3f6;

          &.toggle {
            background-color: #1a82ff;
            border-color: #1a82ff;
            color: white;
          }
        }
      }

      &.ignored:not(.toggle) {
        color: var(--text-light);
      }

      &:hover:not(.selected) {
        td {
          background-color: var(--upload-hover-bg);
        }
      }
    }

    td {
      border: 1px solid var(--text-light);
      white-space: nowrap;
      padding: 4px 8px;
      min-width: 16px;
      user-select: none;
      max-width: 240px;
      overflow: hidden;
      text-overflow: ellipsis;

      // Duplicates an invisible content with font-weight: bold so that when
      // a row is selected and text is bold, it doesn't change the table column widths
      &:not(.toggle) {
        &::after {
          display: block;
          content: attr(title);
          font-weight: bold;
          visibility: hidden;
          height: 0;
        }
      }

      &.toggle {
        padding-inline: 16px;
        text-align: center;

        .form-check {
          margin: 0;
          min-height: unset;
        }
      }
    }
  }

  .header-row-input {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 12px;
    font-size: 15px;

    .form-label {
      margin: 0;
    }

    .form-control {
      font-size: 15px;
      width: 72px;
    }
  }

  .columns-table {
    padding-block: 12px;

    .table-head {
      background-color: var(--table-head-bg);
      font-weight: bold;
      font-size: 14px;
      border-radius: 8px;
      display: flex;
      align-items: center;
      padding: 8px 12px;
      gap: 8px;
      position: sticky;
      top: -24px;
      z-index: 2;
    }

    .table-row {
      display: flex;
      align-items: center;
      padding: 6px 12px;
      gap: 8px;
      min-height: 50px;

      &:not(:last-of-type)  {
        border-bottom: 1px solid var(--table-row-border);
      }

      .imported-column {
        font-family: var(--font-monospace);
        font-size: 14px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        
        &.empty-field {
          font-style: italic;
          font-family: var(--font-sans-serif);
          color: var(--text-light);
        }
      }
    }

    .unselected-field {
      font-style: italic;
      color: var(--text-light);
      font-size: 14px;
    }

    .toggle-column {
      min-width: 24px;
    }

    .imported-column {
      flex: 1;
    }

    .match-column {
      flex: 2;
      min-width: 1px; // prevents overflow of super-long-names for example in custom fields
    }
  }

  .import-dropdown {
    width: 248px;
    max-width: 100%;
    color: var(--text-color);
    font-size: 15px;
  
    .label {
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .dropdown-header {
      font-style: italic;
    }
  
    .dropdown-menu {
      border-color: #E5E5E5;
      min-width: 248px;
      column-rule: 1px solid rgba(0, 0, 0, 0.175);

      .field-columns {
        display: flex;
        gap: 4px;

        div {
          &:nth-child(2) {
            max-height: 452px;
            overflow: auto;    
            border-left: 1px solid #e9e9e9;
          }
        }
      }
  
      .dropdown-item {
        display: flex;
        font-size: 15px;
        line-height: 24px;
        gap: 12px;
        align-items: center;
        color: var(--text-color);
        width: 248px;
  
        &.active, &:active {
          background-color: var(--item-active-color);
          color: var(--text-color);
        }

        &.reset-column {
          font-style: italic;
        }
      }
    }
    
    .import-toggle {
      all: unset;
      box-sizing: border-box;
      cursor: pointer;
      display: block;
      min-width: 100px;
      width: 100%;
      font-size: 15px;
      
      border: 1px solid transparent;
      border-radius: 8px;
      white-space: nowrap;
  
      border: 1px solid #E2E2E2;
    
      &::after {
        content: none;
      }
    
      &:hover, &.show {
        background-color: white;
        border: 1px solid #E2E2E2;
    
        svg.chevron {
          // opacity: 1;
          transform: rotate(180deg);
        }
      }
    
      .toggle-overflow {
        display: flex;
        gap: 6px;
        align-items: center;
        padding: 6px 12px;
      }
    
      .label {
        overflow: hidden;
        text-overflow: ellipsis;
        flex: 1;
  
        &-placeholder {
          color: #919191;
          font-style: italic;
        }
      }
    
      svg.chevron {
        width: 12px;
        height: 12px;
        fill: #888888;
        opacity: 1;
        margin-top: 4px;
        transform: rotate(180deg);
      }
    }
  }

  .option-dropdown {
    display: flex;
    align-items: center;
    gap: 0;
    margin-block: 24px;
    flex-wrap: wrap;
    
    .option-description {
      flex: 4;
    }

    .option-edit {
      text-align: right;
      width: 100%;
      
      button {
        all: unset;
        box-sizing: border-box;
        cursor: pointer;
        padding: 4px;

        color: var(--link-color);
        font-style: italic;
        
        &:hover {
          text-decoration: underline;
        }
      }
    }
    
    .dropdown {
      flex: 3;
      min-width: 0;

      &.__lead-dropdown .dropdown-menu {
        max-width: 100%;
      }
    }

    .distribution-dropdown.__lead-dropdown .dropdown-menu {
      padding-block: 2px;

      .dropdown-item {
        display: block;
        padding: 8px 12px;
        border-radius: 8px;
        width: unset;

        .dropdown-value {
          font-weight: bold;
        }

        .dropdown-description {
          white-space: normal;
          font-size: 13px;
          line-height: 20px;
          
          .active & {
            color: #8796FF;
          }
        }
      }
    }
  }

  .form-check {
    margin-top: 32px;
    font-weight: bold;
  }

  .distribution-description {
    margin-top: 12px;
    color: var(--text-color);
    font-size: 15px;
  }
}

.__modal {
  --header-text-color   : #5F5E5E;
  --text-color          : #414141;
  --primary-btn-bg      : #4C62FB;
  --secondary-btn-bg    : #F1F1F1;
  --secondary-btn-color : #797979;

  [data-theme=dark] & {
    --header-text-color   : #dee2e6;
    --text-color          : #dddee2;
    --primary-btn-bg      : #2e47f3;
    --secondary-btn-bg    : #313131;
    --secondary-btn-color : #b9b9b9;
  }

  .modal {
    &-header {
      border-bottom-color: transparent;
      font-size: 20px;
      color: var(--header-text-color);
      padding: 4px 16px;

      .btn-close {
        font-size: 12px;
      }

      h2 {
        font-size: 22px;
        margin-top: 16px;
        margin-left: 8px;
      }
    }

    &-body {
      color: var(--text-color);
      padding: 24px;
    }

    &-footer {
      border-top-color: transparent;
      padding: 24px;
      padding-top: 8px;
      gap: 8px;

      button {
        all: unset;
        box-sizing: border-box;
        cursor: pointer;
        user-select: none;
        border-radius: 32px;
        padding: 6px 24px;
        min-width: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
        
        &.cancel {
          color: var(--secondary-btn-color);

          &:hover {
            background-color: var(--secondary-btn-bg);
          }

          &:focus {
            outline: solid 4px rgba(var(--secondary-btn-bg), 0.7);
          }
        }
        
        &.confirm {
          background-color: var(--primary-btn-bg);
          transition: 0.3s background-color;
          font-weight: bold;
          color: white;

          &:hover {
            background-color: #3b55ff;
          }

          &:disabled {
            opacity: 0.7;
            cursor: initial;
          }

          &:focus {
            transition: 0.15s outline;
            outline: solid 4px rgba(blue, 0.2);
          }
        }
      }
    }
  }
}

.__review-leads-modal {

  &-summary {
    .modal-dialog {
      width: 550px;
    }
  }
  
  &.__modal .modal-body {
    color: #5F5F5F;
  }

  .summary {
    border: 1px dashed #B4B4B4;
    border-radius: 8px;
    padding: 20px 32px;
    margin-block: 32px;

    p:last-of-type {
      margin-bottom: 0;
    }

    .success {
      strong {
        color: #0BA536;
      }
      
      > svg {
        width: 20px;
        height: auto;
        color: #0BA536;
        vertical-align: text-top;

        path {
          fill: currentColor;
        }
      }
    }

    .error {
      strong {
        color: #FE0707;
      }

      > svg {
        width: 20px;
        height: auto;
        color: #FE0707;
        vertical-align: text-top;

        path {
          fill: currentColor;
        }
      }
    }
  }

  .leads {
    .checkbox {
      width: 32px;
      flex-shrink: 0;
    }

    .table-head {
      display: flex;
      gap: 8px;
      background-color: #F7F7FA;
      border-radius: 4px;
      font-size: 14px;
      color: #5C5C5C;
      font-weight: bold;
      width: fit-content;
      margin-bottom: 12px;
      position: sticky;
      top: -24px;

      > div {
        padding: 8px;
      }
    }

    .table-body {
      .table-row {
        display: flex;
        align-items: flex-start;
        gap: 8px;
        margin-bottom: 12px;
        width: fit-content;

        &:hover {
          .actions {
            visibility: visible;
          }
        }

        &:not(:last-of-type) {
          border-bottom: 1px solid #F2F2F6;
        }

        .checkbox {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-top: 2px;

          button {
            all: unset;
            box-sizing: border-box;
            cursor: pointer;

            &.add {
              color: white;
              background-color: #4C62FB;
              border-radius: 32px;
              padding: 6px 20px;
              font-weight: bold;
              font-size: 15px;
            }

            &.delete {
              color: #FE0707;
            }
          }
        }    
      }
    }

    .input-field {
      width: 240px;
      flex-shrink: 0;
      min-width: 0;
    }

    .input-completed {
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 15px;
      padding: 8px;
    }
    
    .form-control {
      font-size: 14px;

      &.is-invalid {
        border-color: #f8b9bf;
      }
    }

    .invalid-feedback {
      font-size: 13px;

      position: absolute;
      max-width: 240px;
      background-color: #dc3545;
      color: white;
      font-weight: 100;
      padding: 4px 8px;
      border-radius: 4px;
    }
  }
}

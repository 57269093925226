.__password-input {
  input {
    border-right-color: transparent;
  }

  .reveal-button {
    all: unset;
    cursor: pointer;
    box-sizing: border-box;
    border: 1px solid #dee2e6;
    border-left-color: transparent;
    border-radius: 0 8px 8px 0 !important; // ugh bootstrap, feedback is last element so input group messes the border radious
    color: #959595;
    padding: 8px 12px;
    transition: 0.3s all;
    
    &:hover, &:focus {
      color: #959595;
      background-color: #fafafa;
      border-left-color: #efefef;
    }

    > svg {
      display: block;
      width: 20px;
      height: 20px;

      path {
        fill: currentColor;
      }
    }
  }
}

.__auth-route {
  &-navigation {
    display: flex;
    height: 100vh;
    width: 100%;
    overflow: hidden;

    .main-content {
      overflow: auto;
      flex: 1;
    }
  }
}

.__kanban-column {
  display: flex;
  flex-direction: column;
  grid-auto-rows: max-content;
  overflow: hidden;
  box-sizing: border-box;
  appearance: none;
  outline: none;
  min-width: 244px;
  margin: 10px;
  height: 80vh;
  transition: background-color 350ms ease;
  
  .items {
    border-radius: 8px;
    background-color: #F6F6F6;
    height: 100%;
  }

  ul {
    display: grid;
    grid-gap: 10px;
    grid-template-columns: repeat(var(--columns, 1), 1fr);
    list-style: none;
    padding: 16px;
    margin: 0;
  }

  &.scrollable {
    .items {
      overflow-y: auto;
    }
  }

  &.placeholder {
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color: rgba(0, 0, 0, 0.5);
    background-color: transparent;
    border-style: dashed;
    border-color: rgba(0, 0, 0, 0.08);

    &:hover {
      border-color: rgba(0, 0, 0, 0.15);
    }
  }

  &.hover {
    .items {
      background-color: #f1f3fe;
    }
  }

  &.unstyled {
    overflow: visible;
    background-color: transparent !important;
    border: none !important;
  }

  &.horizontal {
    width: 100%;

    ul {
      grid-auto-flow: column;
    }
  }

  &.shadow {
    box-shadow: 0 1px 10px 0 rgba(34, 33, 81, 0.1);
  }

  &:focus-visible {
    border-color: transparent;
    box-shadow: 0 0 0 2px rgba(255, 255, 255, 0), 0 0px 0px 2px #4c9ffe;
  }

  .header {
    display: flex;
    padding: 4px 12px;
    padding-right: 8px;
    align-items: center;
    justify-content: space-between;
    font-weight: 800;
    margin-bottom: 8px;
    color: #363636;
    font-size: 15px;
  
    &:hover {
      .Actions > * {
        opacity: 1 !important;
      }
    }
  }
  
  .Actions {
    display: flex;
  
    > *:first-child:not(:last-child) {
      opacity: 0;
  
      &:focus-visible {
        opacity: 1;
      }
    }
  }
}


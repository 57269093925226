body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: var(--font-sans-serif);
}

code {
  /* font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace; */
  font-family: var(--font-monospace);
}

html {
  --dev-outline-color: #0722d32d;
  --font-monospace: 'Inconsolata', source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
  --font-sans-serif: 'Mulish', 'Roboto', 'Helvetica Neue', sans-serif;
}

html[data-theme=dark] {
  background-color: #212529;
  color: #e0e0e0;

  --dev-outline-color: #5266e241;
}

body.__dev-outlines * {
  outline: 1px solid var(--dev-outline-color);
}

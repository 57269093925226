.__users {
  margin: 48px;
  max-width: 1080px;

  .title-actions {
    margin-bottom: 20px;
    display: flex;
    gap: 16px;

    h2 {
      color: #5F5E5E;
      font-weight: bold;
      font-size: 24px;
      margin: 0;
      flex: 1;
    }

    .search {
      background-color: #F1F1F1;
      display: flex;
      padding: 2px 12px;
      border-radius: 32px;
      width: 300px;
      align-items: center;
      gap: 4px;

      svg {
        width: 20px;
        height: 20px;

        path {
          fill: #989898;
        }
      }

      input {
        all: unset;
        box-sizing: border-box;
        color: #5F5E5E;
        flex: 1;
        font-size: 15px;
        padding: 4px;

        &::placeholder {
          color: #989898;
        }
      }

      button.clear {
        all: unset;
        box-sizing: border-box;
        cursor: pointer;
        display: block;
        margin-bottom: 2px;
        margin-right: 2px;
        padding: 4px;
        color: #989898;
        line-height: 12px;
        // font-weight: bold;
        font-size: 18px;

        &:hover {
          color: #5F5E5E;
        }
      }
    }

    button.add-user {
      all: unset;
      box-sizing: border-box;
      cursor: pointer;
      background-color: #4C62FB;
      color: white;
      border-radius: 32px;
      padding: 4px 24px;
      font-size: 15px;
    }
  }

  p {
    color: #5F5F5F;
    font-size: 15px;
  }

  .no-matches {
    margin-top: 60px;
    padding: 32px 20px;
    border: 1px dashed #AAAAB2;
    border-radius: 8px;
    color: #5F5F5F;
    text-align: center;
    font-style: italic;
    font-size: 15px;

    button {
      all: unset;
      box-sizing: border-box;
      cursor: pointer;
      color: #469BFF;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .match-clear {
    text-align: right;
    font-style: italic;
    font-size: 14px;
    color: #8D8D8D;

    button {
      all: unset;
      box-sizing: border-box;
      cursor: pointer;
      color: #469BFF;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  table {
    width: 100%;
    margin-top: 16px;
    table-layout: fixed;

    thead {
      th {
        padding: 8px;
        background-color: #F7F7FA;

        button.sort-by {
          all: unset;
          user-select: none;
          cursor: pointer;
          padding: 4px 8px;
          color: #5C5C5C;
          font-size: 14px;
          border-radius: 8px;

          &:hover {
            background-color: white;
            box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.05);
          }

          &.selected {
            color: #4C62FB;
          }

          .arrow {
            margin-left: 6px;
            animation: __users-arrow-show 0.3s forwards;
            display: inline-block;
          }
          
          svg {
            width: 9px;
            height: auto;
            fill: #4C62FB;
            vertical-align: baseline;

            &.asc {
              transform: rotate(180deg);
            }
          }
        }

        &:first-of-type {
          border-top-left-radius: 8px;
          border-bottom-left-radius: 8px;
        }

        &:last-of-type {
          border-top-right-radius: 8px;
          border-bottom-right-radius: 8px;
        }

        &.status {
          width: 20%;
        }
      }
    }

    tbody {
      color: #5F5F5F;
      font-size: 15px;

      tr {
        &:not(:last-of-type) {
          border-bottom: 1px solid #F2F2F6;
        }

        &:hover {
          background-color: #FBFBFB;
          cursor: pointer;
        }
      }
      
      td {
        padding: 16px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        &.invitation-pending {
          font-style: italic;
          color: #FE0707;
        }
      }
    }
  }
}

@keyframes __users-arrow-show {
  from {
    opacity: 0;
    transform: translateY(-50%);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}
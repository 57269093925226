.__status-dropdown {
  color: #5F5F5F;
  font-size: 16px;
  
  &.size-sm {
    font-weight: 700;
    font-size: 13px;
  }

  .square {
    width: 20px;
    height: 20px;
    border-radius: 4px;
    flex-shrink: 0;
  }

  .label {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .dropdown-menu {
    border-color: #E5E5E5;
    // padding-block: 10px;
    width: 100%;
    max-height: 50vh;
    overflow: auto;

    .dropdown-item {
      display: flex;
      // font-weight: 700;
      // font-size: 13px;
      font-size: 15px;
      line-height: 24px;
      gap: 12px;
      align-items: center;
      color: #5F5F5F;

      &:active {
        background-color: #F2F2F6;
        color: #5F5F5F;
      }
    }
  }
  
  .status-toggle {
    all: unset;
    box-sizing: border-box;
    cursor: pointer;
    display: block;
    min-width: 100px;
    width: 100%;
    
    border: 1px solid transparent;
    border-radius: 8px;
    white-space: nowrap;

    border: 1px solid #E2E2E2;
  
    &::after {
      content: none;
    }
  
    &:hover, &.show {
      background-color: white;
      border: 1px solid #E2E2E2;
  
      svg.chevron {
        // opacity: 1;
        transform: rotate(180deg);
      }
    }
  
    .toggle-overflow {
      display: flex;
      gap: 6px;
      align-items: center;
      padding: 4px 8px; // size-sm
      padding: 8px 12px; 
    }
  
    .label {
      overflow: hidden;
      text-overflow: ellipsis;
      flex: 1;

      &-placeholder {
        color: #919191;
        font-style: italic;
      }
    }
  
    svg.chevron {
      width: 12px;
      height: 12px;
      fill: #888888;
      opacity: 1;
      margin-top: 4px;
      transform: rotate(180deg);
    }
  }
}


.__dev-widget {
  position: fixed;
  bottom: 12px;
  right: 64px;
  width: 40px;
  height: 40px;
  z-index: 2000;

  &-toggle {
    all: unset;
    cursor: pointer;
    box-sizing: border-box;

    &:hover {
      filter: saturate(1.1) brightness(1.1);
    }

    > svg {
      width: 100%;
      height: auto;
    }
  }
}

.__business-toggle {
  width: 92%;
  margin: 0 auto 20px;
  user-select: none;

  .business-logo {
    background-color: #c0c0c0;
    border-radius: 100%;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 28px;
    height: 28px;
    color: white;
  }
  
  .business-name {
    flex: 1;
    color: #4A4A4A;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 15px;
  }
  
  .toggle-overflow {
    display: flex;
    background-color: white;
    padding: 6px 8px;
    border-radius: 8px;
    border: 1px solid #E6E6E6;
    box-shadow: 0 2px 6px 0 rgba(black, 0.05);
    align-items: center;
    gap: 8px;

    .chevron {
      transform: rotate(180deg);
    }
  }
  
  .dropdown {
    &.show {
      .dropdown-toggle {
        .toggle-overflow {
          border-color: #C1C1C1;
          box-shadow: 0 0 200px 320px rgba(black, 0.05);
          border-bottom-right-radius: 0;
          border-bottom-left-radius: 0;
          padding: 12px 8px;
          transition: 0.3s padding, 0.3s box-shadow;
        }

        .chevron {
          transform: none;
          transition: 0.3s transform;
        }
      }
    }

    .dropdown-toggle {
      all: unset; 
      box-sizing: border-box;
      cursor: pointer;

      .toggle-overflow {
        &:hover {
          border-color: #C1C1C1;
        }    
      }
      
      &::after {
        content: none;
      }      
    }
    
    .dropdown-menu {
      width: 100%;
      border: none;
      background-color: transparent;
      
      .transition-container {
        box-sizing: border-box;
        // padding: 20px;
        border-radius: 0 0 8px 8px;
        border: 1px solid #C1C1C1;
        border-top-color: #E6E6E6;
        box-shadow: 0px 6px 12px 0px rgba(0, 0, 0, 0.08);
        animation: __business-show-menu 0.2s forwards;
        background-color: white;
        padding: 4px;
      }

      .dropdown-divider {
        border-color: #E6E6E6;
      }

      .dropdown-item {
        padding: 12px 4px;
        display: flex;
        gap: 8px;
        border-radius: 4px;
        align-items: center;
        
        &:active {
          background-color: #E6E6E6;
        }
        
        &.create-new {
          margin-top: 12px;
          text-align: center;
          color: #3f92f3;
          display: block;
          font-size: 14px;
        }
      }
    }
  }
}

@keyframes __business-show-menu {
  from {
    opacity: 0;
    transform: translateY(-32px);
  }

  to {
    opacity: 1;
    // from bootstrap: translate3d(2px, 32px, 0px);
    transform: translateY(0px);
  }
}

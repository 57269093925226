.__lead-details-modal {
  --text-color: #5F5F5F;
  --warning-text : #DC0707;

  [data-theme=dark] & {
    --text-color: #dddee2;
    --warning-text : #f16161;
  }

  .modal-dialog {
    width: 1000px;
    max-width: 90vw;
  }

  .modal-content {
    max-height: 90vh;
    min-height: 640px;

    @media screen and (max-height: 800px) {
      min-height: 80vh;
    }
  }

  &.__modal .modal-body {
    @media screen and (max-height: 800px) {
      padding-block: 12px 8px;
    }
  }
  
  .__lead-dropdown .dropdown-menu {
    max-height: 280px;

    @media screen and (max-height: 800px) {
      max-height: 30vh;
    }
  }

  .modal-body {
    display: flex;
    gap: 32px;
    overflow: hidden;

    h3 {
      color: var(--text-color);
      font-weight: bold;
      font-size: 18px;
      margin-top: 24px;
      margin-bottom: 16px;
    }
  }

  .modal-footer {
    align-items: stretch;

    .delete-section {
      flex: 1;
      margin: 0;

      button.delete-lead {
        color: var(--warning-text);
        display: flex;
        gap: 8px;
        padding-inline: 8px;

        &:hover {
          text-decoration: underline;
        }

        > svg {
          width: 16px;
          height: auto;
          fill: currentColor;
        }
      }
    }
  }

  .field {
    margin-bottom: 10px;
    gap: 8px;

    label {
      text-align: right;
      color: var(--text-color);
      font-size: 14px;
      margin-bottom: 4px;
      display: flex;
      justify-content: space-between;
    }

    input {
      flex: 2;
      font-size: 14px;
    }
  }

  button.toggle-name {
    all: unset;
    cursor: pointer;
    color: #0075FF;
    font-style: italic;
    font-size: 14px;
  }

  .main-fields {
    flex: 2;
    overflow: auto;
    padding-right: 4px;

    &:hover {
      &::-webkit-scrollbar-thumb {
        background-color: rgba(black, 0.2);
      }
    }

    &::-webkit-scrollbar {
      -webkit-appearance: none;
      width: 8px;
    }
    
    &::-webkit-scrollbar-thumb {
      border-radius: 6px;
      background-color: transparent;
    }
  }
  
  .side-fields {
    flex: 1;
    padding-inline: 4px;
    overflow: auto;

    &:hover {
      &::-webkit-scrollbar-thumb {
        background-color: rgba(black, 0.2);
      }
    }

    &::-webkit-scrollbar {
      -webkit-appearance: none;
      width: 8px;
    }
    
    &::-webkit-scrollbar-thumb {
      border-radius: 6px;
      background-color: transparent;
    }
  }

  .field-columns {
    display: flex;
    gap: 36px;
    justify-content: stretch;
    padding-inline: 4px;

    > div {
      flex: 1;
    }
  }

  .__lead-dropdown {
    .lead-toggle {
      height: 35px;

      .toggle-overflow {
        padding-block: 2px;
      }
    }

    .dropdown-menu {
      max-width: 294px;
      min-width: 294px;
    }
  }

  .info-icon {
    cursor: pointer;
  }

  .custom-fields-title {
    display: flex;
    align-items: center;
    gap: 8px;

    margin-top: 24px;
    margin-bottom: 16px;

    h3 {
      margin: 0;
    }
  }

  .custom-fields {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    column-gap: 40px;
    padding: 4px;

    label {
      display: flex;
      gap: 4px;
      align-items: center;
      justify-content: space-between;
      line-height: 22px;

      > span {
        text-overflow: ellipsis;
        white-space: nowrap;
        max-width: 100%;
        overflow: hidden;
      }

      > svg {
        flex-shrink: 0;
        
        circle {
          fill: #C8C8C8;
        }
      }

      &.monospace {
        font-family: var(--font-monospace);
        font-size: 13px;
        font-weight: bold;
      }
    }
  }

  .add-custom-field {
    border: 1px dashed #d2d2d2;
    padding: 24px;
    text-align: center;
    border-radius: 4px;
    font-size: 15px;

    font-style: italic;

    color: #888888;
    
    a {
      color: #469BFF;

      &:hover {
        text-decoration: underline;
      }
    }
  }


  .notes {
    textarea {
      font-size: 14px;
    }

    .all-notes {
      margin-top: 8px;
      padding-left: 8px;
      border-left: 3px solid #D9D9D9;
    }

    .notes-title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-block: 24px 12px;
  
      h3 {
        margin: 0;
      }
  
      button.add-note {
        all: unset;
        box-sizing: border-box;
        cursor: pointer;
        color: #469BFF;
        font-size: 15px;
        padding: 2px 8px;
  
        &:hover {
          text-decoration: underline;
        }
      }
    }

    .add-first-note {
      border: 1px dashed #d2d2d2;
      padding: 24px;
      text-align: center;
      border-radius: 4px;

      > button {
        all: unset;
        cursor: pointer;
        box-sizing: border-box;
        color: #469BFF;
        font-style: italic;
        font-size: 15px;
        padding: 4px 8px;

        &:hover {
          text-decoration: underline;
        }
      }
    }

    .editing-note {
      .cancel-save-note {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 4px;
        margin-top: 8px;

        button {
          all: unset;
          box-sizing: border-box;
          cursor: pointer;
          user-select: none;
          padding: 2px 4px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 14px;
          min-width: 32px;
          
          &.cancel {
            color: #797979;
  
            &:hover, &:focus {
              text-decoration: underline;
            }
          }
          
          &.confirm {
            color: #469BFF;
  
            &:hover, &:focus {
              text-decoration: underline;
            }
  
            &:disabled {
              opacity: 0.7;
              cursor: initial;
            }
          }
        }
      }
    }

    .note {
      font-size: 14px;
      white-space: pre-line;
      margin-block: 16px;

      &:first-of-type {
        margin-top: 0;
      }

      &:hover {
        .note-actions {
          button {
            visibility: visible;
          }
        }
      }

      .note-actions {
        display: flex;
        align-items: center;
        margin-bottom: 2px;

        button {
          all: unset;
          box-sizing: border-box;
          cursor: pointer;
          padding: 2px 6px;
          margin-left: 8px;
          display: flex;
          gap: 4px;
          visibility: hidden;

          &:hover {
            text-decoration: underline;
          }
        }

        button.delete {
          color: #FE0707;

          > svg {
            width: 16px;
            height: auto;
            fill: currentColor;
          }
        }

        button.edit {
          color: #469BFF;

          > svg {
            width: 14px;
            height: auto;
            margin-bottom: 2px;
            fill: currentColor;
          }
        }
      }

      .note-date {
        color: #8B8B8B;
        font-size: 13px;
        flex: 1;
      }
    }
  }
}


.__code-tooltip {
  .tooltip-inner {
    max-width: 280px;
    font-size: 12px;
    font-family: var(--font-monospace);
    text-align: left;
    font-weight: bold;
    padding: 6px 12px;
  }
}
.__tooltip {
  .tooltip-inner {
    max-width: 280px;
    font-size: 14px;
    font-family: 'Mulish', 'Roboto', 'Helvetica Neue', sans-serif;
    text-align: left;
    padding: 8px 14px;

    a {
      text-decoration: underline;
      color: #8dc2ff;
      font-weight: bold;
    }
  }

  &-small {
    .tooltip-inner {
      font-size: 12px;
      padding: 4px 8px;
    }
  }

  &-no-arrow {
    &.tooltip .tooltip-arrow {
      display: none;
    }

    .bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow::before, .bs-tooltip-top .tooltip-arrow::before {
      border-color: transparent;
    }
  }
}

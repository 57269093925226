.__categories-list {
  margin-block: 40px;

  .title-actions {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;

    h2 {
      margin-bottom: 0;
    }

    button.main-action {
      all: unset;
      box-sizing: border-box;
      cursor: pointer;
      user-select: none;
      display: flex;
      background-color: #F1F1F1;
      color: #797979;
      border-radius: 32px;
      padding: 6px 20px;
      transition: 0.3s background-color;
      font-size: 15px;
      align-items: center;
      gap: 8px;

      &:hover:not(.active) {
        background-color: #E2E2E2;
      }
    }
  }

  .add-category {
    background-color: #FBFBFB;
    border: 1px solid #F2F2F6;
    border-radius: 12px;
    padding: 20px;
    margin: 20px 0;
    font-size: 15px;

    .form-label {
      margin-bottom: 0;
      margin-right: 8px;
    }

    .name-description {
      display: flex;
      align-items: flex-start;
      gap: 40px;

      .name {
        display: flex;
        align-items: center;
        gap: 8px;
        flex: 1;
        flex-wrap: wrap;
      }

      .form-control {
        font-size: 15px;
        padding-block: 4px;
        flex: 1;

        &::placeholder {
          color: #919191;
          font-style: italic;
        }
      }

      .actions {
        display: flex;
        gap: 8px;
        margin-left: auto;

        button {
          all: unset;
          box-sizing: border-box;
          cursor: pointer;
          user-select: none;
          border-radius: 32px;
          padding: 6px 20px;
          font-size: 15px;
          min-width: 90px;
          display: flex;
          align-items: center;
          justify-content: center;
          
          &.cancel {
            color: #797979;

            &:hover {
              background-color: #F1F1F1;
            }
          }
          
          &[type=submit] {
            background-color: #4C62FB;
            transition: 0.3s background-color;
            font-weight: bold;
            color: white;

            &:hover {
              background-color: #3b55ff;
            }

            &:disabled {
              opacity: 0.7;
              cursor: initial;
            }
          }
        }
      }
    }

    .request-error {
      margin-top: 24px;
      font-size: 14px;
      color: #D10000;
      text-align: center;
    }
  }
  
  .categories-table {
    margin-block: 20px;
    color: #414141;


    .table-head {
      display: flex;
      padding: 12px 8px;
      gap: 12px;
      background-color: #F7F7FA;
      font-weight: bold;
      color: #5C5C5C;
      font-size: 14px;
      border-radius: 8px;
      margin-bottom: 4px;
      justify-content: space-between;

      .row-status {
        padding-left: 8px;
      }
    }

    .sortable-item {
      &:hover {
        .__editable-field {
          button.edit {
            opacity: 1;
          }

          .empty-field-add {
            opacity: 1;
          }
        }

        .delete-btn {
          visibility: visible;
        }

        .view-link {
          visibility: visible;
        }

        .enter-description {
          visibility: visible;
        }
      }
    }

    a.view-link {
      display: flex;
      gap: 4px;
      text-decoration: none;
      color: #0075FF;
      visibility: hidden;
      justify-content: center;
      
      &:hover {
        text-decoration: underline;
      }

      svg {
        width: 16px;
        height: auto;
        fill: currentColor;
      }
    }

    .row {
      &-handle {
        flex: 26px 0;
        flex-shrink: 0;
      }

      &-name {
        // flex: 80px 1;
        // flex: 30% 1;
        width: 30%;
        min-width: 0;
      }

      &-leads-count {
        flex: 80px 0;
        text-align: right;
        flex-shrink: 0;
      }

      &-status {
        flex: 152px 0;
        text-align: left;
        flex-shrink: 0;
      }

      &-color {
        flex: 48px 0;
        text-align: center;
      }

      &-description {
        flex: 3;

        .enter-description {
          visibility: hidden;
          color: #8A8A8A;
        }
      }

      &-delete {
        flex: 116px 0;
        flex-shrink: 0;
      }

      &-view {
        flex: 76px 0;
        text-align: center;
        font-size: 14px;
        flex-shrink: 0;
      }

      &-settings {
        flex: 96px 0;
        text-align: center;
        flex-shrink: 0;
      }
    }

    .delete-btn {
      all: unset;
      display: flex;
      gap: 6px;
      box-sizing: border-box;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 6px 16px;
      border-radius: 32px;
      visibility: hidden;
      color: #FE0707;

      &:hover {
        background-color: rgba(#FE0707, 0.07);
        // color: #bb0000;
        // text-decoration: underline;
      }
      
      > svg {
        width: 20px;
        height: auto;
        fill: currentColor;
      }
    }
  }
}

.__oauth-consent {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 100dvw;
  min-height: 100dvh;
  color: var(--text-color);
  background-color: #f1f1f1;

  --border-color: #D8D8E9;
  --text-color: #5F5E5E;

  @media screen and (max-width: 380px) {
    background-color: white;
  }

  h1 {
    text-transform: uppercase;
    color: #4C62FB;
    margin-bottom: 32px;
    text-align: center;
    font-weight: 600;
    font-size: 16px;
    font-family: 'Montserrat', sans-serif;
    white-space: nowrap;
  }

  main {
    width: 360px;
    padding: 32px;
    background-color: white;
    border-radius: 16px;
    box-shadow: 0px 2px 16px 0 rgba(0, 0, 0, 0.1);
    position: relative;

    @media screen and (max-width: 380px) {
      width: 100%;
      box-shadow: none;
      padding: 20px;
    }
  }

  .icons {
    margin: 0 auto 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;

    .app-icon {
      width: 48px;
      height: 48px;
    }
  }

  h2 {
    text-align: center;
    font-size: 22px;
    margin-bottom: 40px;
    line-height: 32px;
  }

  .description {
    border: 1px solid var(--border-color);
    border-radius: 8px;
    padding: 12px;
    margin-bottom: 20px;

    ul {
      margin: 0;
    }
  }

  .accept-deny {
    display: flex;
    justify-content: space-between;
  }

  .button {
    all: unset;
    box-sizing: border-box;
    cursor: pointer;
    border-radius: 32px;
    padding: 8px 28px;
    font-weight: bold;
    transition: 0.3s all;
  }

  .button-cancel {
    color: #797979;

    &:hover {
      background-color: #eeeeee;
    }
  }


  .button-accept {
    color: white;
    background-color: #4C62FB;
  }

  .button-accept:hover {
    filter: brightness(1.2) saturate(1.2);
  }
}

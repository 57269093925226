.__status-color-picker {
  .color {
    width: 24px;
    height: 24px;
    border-radius: 6px;
    border: 3px solid white;
    margin: 0 auto;

    &:hover {
      outline: 1px solid #cdcdcd;
    }
  }

  .dropdown {

    &-menu {
      padding: 0;
      border: none;
      background-color: transparent;
      transform: translateX(-2px) translateY(-14px) !important; // override default positioning

      .transition-container {
        padding: 12px;
        display: flex;
        gap: 2px;
        background-color: white;
        border-radius: 8px;
        box-shadow: 0 2px 7px 0 rgba(black, 0.25);
        animation: __category-picker-show 0.3s forwards;
        transform-origin: left;
      }
    }

    &-divider {
      border-left: 1px solid #E2E2E2;
      height: auto;
      margin: 0 4px;
    }

    &-item {
      padding: 2px;
  
      &:hover {
        background-color: white;

        .color {
          outline: 1px solid #cdcdcd;
        }
      }
    }
  }
}

@keyframes __category-picker-show {
  from {
    transform: scaleX(0.8);
    opacity: 0;
  }
  
  to {
    transform: scaleX(1);  
    opacity: 1;
  }
}
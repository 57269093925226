.__lead-distribution {
  max-width: 880px;
  margin-block: 40px;

  h2 {
    margin-bottom: 20px;
  }

  .option {
    display: flex;
    justify-content: space-between;
    gap: 12px;
    padding: 16px;
    border-radius: 8px;
    color: #5F5F5F;
    margin-left: 8px;
    border: 1px solid transparent;
    margin-block: 12px;
    
    &:hover:not(.selected) {
      border-color: #dcdcdc;
      transition: 0.3s border-color;
    }

    &.selected {
      border-color: #0BA536;
      outline: 1px solid #0BA536;
      background-color: #f4fff4;
      
      .label {
        color: #0BA536;
        transition: 0.3s color;
      }
    }

    .radio-box {
      width: 20px;
      height: 20px;
      border-radius: 100%;
      border: 1px solid #B4B4B4;
      margin-top: 2px;
    }

    .check-icon {
      width: 20px;
      height: 20px;
      margin-top: 2px;
      color: #0BA536;

      path {
        fill: currentColor;
      }
    }

    .label-description {
      flex: 1;
    }

    .label {
      font-weight: bold;
      margin-bottom: 4px;
    }

    .description {
      font-size: 15px;
      font-style: italic;
    }

    button.edit-distribution {
      all: unset;
      box-sizing: border-box;
      cursor: pointer;
      background-color: #0BA536;
      align-self: center;
      color: white;
      border-radius: 32px;
      padding: 8px 20px;
      min-width: 120px;
      font-weight: bold;
      transition: 0.3s background-color;
      font-size: 15px;
      text-align: center;
      min-width: 124px;
  
      .spinner-border {
        --bs-spinner-border-width: 0.15em;
      }
  
      &:disabled {
        opacity: 0.7;
        cursor: initial;
      }
  
      &:hover {
        transition: 0.3s background-color;
        background-color: #18C548;
      }
  
      &:focus {
        transition: 0.15s outline;
        outline: solid 4px rgba(#18C548, 0.2);
      }
    }
  }
}

.__lead-import-notifications {
  position: relative;
  z-index: 1000;

  .notification-bar {
    background-color: #515FE5;
    color: white;
    
    font-weight: 700;
    text-align: center;
    padding: 8px 4px;
    font-size: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    min-height: 44px;

    &.warning {
      background-color: #fae8b5;
      background-color: #ffd43b;
      color: #4f3713;
    }

    &.success {
      background-color: #219642;
    }

    &.error {
      background-color: #c21a1a;
    }

    .spinner-border {
      --bs-spinner-width: 18px;
      --bs-spinner-height: 18px;
      --bs-spinner-border-width: 2px;
      --bs-spinner-animation-speed: 1.3s;
    }

    button.action {
      all: unset;
      box-sizing: border-box;
      cursor: pointer;
      background-color: rgba(white, 0.25);
      border-radius: 8px;
      padding: 2px 12px;
      margin-left: 8px;
      transition: 0.3s background-color;

      &:hover {
        background-color: rgba(white, 0.15);
      }
    }
  }

}

.__business-settings {
  max-width: 1000px;

  h2 {
    color: #5F5E5E;
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
  }

  .content {
    padding: 0 40px;
    margin: 20px auto 40px;

    .section {
      margin-left: 16px;
      margin-bottom: 36px;
    }

    form.single-field {
      max-width: 540px;

      label {
        margin-bottom: 8px;
      }

      .label-url-copied {
        display: flex;
        justify-content: space-between;

        .url-copied {
          color: #0BA536;
          font-size: 14px;
          animation: category-settings-url-copied-show 2s forwards;
        }
      }
    }

    .errors {
      width: 100%;
      margin-top: .25rem;
      font-size: .875em;
      color: var(--bs-form-invalid-color);
    }

    .input-button {
      display: flex;
      gap: 4px 16px;
      margin-bottom: 16px;
      flex-wrap: wrap;

      .invalid-feedback {
        width: 100%;
      }

      input {
        border-radius: 8px;
        color: #5F5F5F;
        flex: 1;
      }

      button[type=submit] {
        all: unset;
        box-sizing: border-box;
        cursor: pointer;
        background-color: #4C62FB;
        color: white;
        border-radius: 32px;
        padding: 8px 20px;
        min-width: 120px;
        font-weight: bold;
        transition: 0.3s background-color;
        font-size: 16px;
        text-align: center;
        min-width: 124px;
    
        .spinner-border {
          --bs-spinner-border-width: 0.15em;
        }
    
        &:disabled {
          opacity: 0.7;
          cursor: initial;
        }
    
        &:hover {
          transition: 0.3s background-color;
          background-color: #3b55ff;
        }
    
        &:focus {
          transition: 0.15s outline;
          outline: solid 4px rgba(blue, 0.2);
        }
      }
    }
  }

  .danger-zone {
    width: 680px;
    margin-top: 40px;
  }

  .user-roles {
    margin-top: 20px;
    font-style: italic;
    color: #5F5F5F;

    a {
      color: #469BFF;
    }
  }

  .delete-business {
    margin-top: 20px;
    border: 1px solid #FF8282;
    color: #DC0707;
    border-radius: 8px;
    padding: 20px 24px;
    display: flex;
    align-items: center;
    gap: 24px;

    .warning-message {
      font-style: italic;
    }

    button.delete {
      all: unset;
      box-sizing: border-box;
      cursor: pointer;
      flex-shrink: 0;
      color: white;
      background-color: #FE0707;
      padding: 8px 24px;
      font-weight: bold;
      border-radius: 128px;
    }
  }
}

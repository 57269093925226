$font-weight: 400;
$border-color: #efefef;
$text-color: #333;
$handle-color: rgba(0, 0, 0, 0.25);
$box-shadow-border: 0 0 0 calc(1px / var(--scale-x, 1)) rgba(63, 63, 68, 0.05);
$box-shadow-common: 0 1px calc(3px / var(--scale-x, 1)) 0 rgba(34, 33, 81, 0.15);
$box-shadow: $box-shadow-border, $box-shadow-common;
$focused-outline-color: #4c9ffe;

@keyframes pop {
  0% {
    transform: scale(1);
    box-shadow: var(--box-shadow);
  }
  100% {
    transform: scale(var(--scale));
    box-shadow: var(--box-shadow-picked-up);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.__kanban-item-wrapper {
  display: flex;
  box-sizing: border-box;
  transform: translate3d(var(--translate-x, 0), var(--translate-y, 0), 0)
    scaleX(var(--scale-x, 1)) scaleY(var(--scale-y, 1));
  transform-origin: 0 0;
  touch-action: manipulation;
  box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  border-radius: 4px;

  &.fadeIn {
    animation: fadeIn 500ms ease;
  }

  &.dragOverlay {
    --scale: 1.05;
    --box-shadow: $box-shadow;
    --box-shadow-picked-up: $box-shadow-border,
      -1px 0 15px 0 rgba(34, 33, 81, 0.01),
      0px 15px 15px 0 rgba(34, 33, 81, 0.25);
    z-index: 999;
  }
}

.__kanban-item {
  position: relative;
  // display: flex;
  flex-grow: 1;
  // max-width: 220px;
  align-items: center;
  // padding: 18px 20px;
  background-color: white;
  // box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.10);
  outline: none;
  // border-radius: calc(4px / var(--scale-x, 1));
  // border-radius: 8px;
  padding: 8px;
  box-sizing: border-box;
  list-style: none;
  transform-origin: 50% 50%;

  -webkit-tap-highlight-color: transparent;

  color: $text-color;
  font-weight: $font-weight;
  font-size: 14px;
  overflow: hidden;

  transform: scale(var(--scale, 1));
  transition: box-shadow 200ms cubic-bezier(0.18, 0.67, 0.6, 1.22);

  // &::before {
  //   content: '';
  //   position: absolute;
  //   top: 50%;
  //   transform: translateY(-50%);
  //   left: 0;
  //   height: 100%;
  //   width: 3px;
  //   display: block;
  //   background-color: #2ed55d;
  // }

  &:focus-visible {
    box-shadow: 0 0px 4px 1px $focused-outline-color, $box-shadow;
  }

  &:not(.withHandle) {
    touch-action: manipulation;
    cursor: grab;
  }

  &.dragging:not(.dragOverlay) {
    opacity: var(--dragging-opacity, 0.5);
    z-index: 0;

    &:focus {
      box-shadow: $box-shadow;
    }
  }

  .content {
    .email {
      color: #8A8A8A;
      font-size: 12px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    .date {
      color: #818181;
      font-size: 12px;
      margin-top: 12px;
      font-style: italic;
    }
  }

  &.disabled {
    color: #999;
    background-color: #f1f1f1;
    &:focus {
      box-shadow: 0 0px 4px 1px rgba(0, 0, 0, 0.1), $box-shadow;
    }
    cursor: not-allowed;
  }

  &.dragOverlay {
    cursor: inherit;
    /* box-shadow: 0 0px 6px 2px $focused-outline-color; */
    animation: pop 200ms cubic-bezier(0.18, 0.67, 0.6, 1.22);
    transform: scale(var(--scale));
    box-shadow: var(--box-shadow-picked-up);
    opacity: 1;
    box-shadow: 0px 4px 12px 0px rgba(black, 0.1);
  }

  &:hover {
    .Remove {
      visibility: visible;
    }
  }
}

.Remove {
  visibility: hidden;
}

.Actions {
  display: flex;
  align-self: flex-start;
  margin-top: -12px;
  margin-left: auto;
  margin-bottom: -15px;
  margin-right: -10px;
}
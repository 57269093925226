.__add-lead-status {
  background-color: #FBFBFB;
  border: 1px solid #F2F2F6;
  border-radius: 12px;
  padding: 20px;
  margin: 20px 0;
  font-size: 15px;

  &-modal {
    .modal-dialog {
      max-width: 386px;
    }

    .__add-lead-status {
      background-color: initial;
      border: none;
      margin: 0;
      padding: 0;

      .name-description {
        flex-direction: column;
        gap: 16px;

        .name, .description {
          width: 100%;
          gap: 4px;
        }

        .form-label {
          flex: 2;
        }

        .form-control {
          flex: 3;
        }
      }
      
      .colors-container {
        flex-wrap: wrap;

        .label {
          width: 140px;
        }

        .color.middle {
          margin-left: 158px;
        }
      }

      .actions {
        margin-top: 32px;
      }
    }
  }

  .form-label {
    margin-bottom: 0;
    margin-right: 8px;
  }

  .name-description {
    display: flex;
    align-items: flex-start;
    gap: 40px;

    .name {
      display: flex;
      align-items: center;
      gap: 8px;
      flex: 1;
      flex-wrap: wrap;
    }

    .description {
      display: flex;
      align-items: center;
      gap: 8px;
      flex: 2;
      flex-wrap: wrap;
    }

    .form-control {
      font-size: 15px;
      padding-block: 4px;
      flex: 1;

      &::placeholder {
        color: #919191;
        font-style: italic;
      }
    }
  }

  .color-actions {
    display: flex;
    justify-content: space-between;
    gap: 8px;
    margin-top: 20px;
    flex-wrap: wrap;

    .colors-container {
      display: flex;
      align-items: center;
      gap: 6px;

      .label {
        margin-right: 12px;
      }

      .color {
        width: 24px;
        height: 24px;
        border-radius: 6px;
        border: 3px solid white;
    
        &:hover {
          outline: 1px solid #cdcdcd;
        }
        
        &.selected {
          outline: 1px solid #999999;
        }
      }
    }

    .actions {
      display: flex;
      gap: 8px;
      margin-left: auto;

      button {
        all: unset;
        box-sizing: border-box;
        cursor: pointer;
        user-select: none;
        border-radius: 32px;
        padding: 6px 20px;
        font-size: 15px;
        min-width: 90px;
        display: flex;
        align-items: center;
        justify-content: center;
        
        &.cancel {
          color: #797979;

          &:hover {
            background-color: #F1F1F1;
          }
        }
        
        &[type=submit] {
          background-color: #4C62FB;
          transition: 0.3s background-color;
          font-weight: bold;
          color: white;

          &:hover {
            background-color: #3b55ff;
          }

          &:disabled {
            opacity: 0.7;
            cursor: initial;
          }
        }
      }
    }
  }

  .request-error {
    margin-top: 24px;
    font-size: 14px;
    color: #D10000;
    text-align: center;
  }
}

.__edit-columns {
  --bg-color     : white;
  --button-bg    : white;
  --toggle-color : #B3B3B3;
  --text-color   : #3A3A3A;
  --checkbox-bg  : #469BFF;
  --primary-bg   : #4C62FB;
  --cancel-color : #797979;
  --cancel-hover : #333333;
  
  [data-theme=dark] & {
    --bg-color     : #212529;
    --button-bg    : #282c30;
    --toggle-color : white;
    --text-color   : #dddee2;
    --checkbox-bg  : #1e70cd;
    --primary-bg   : #2e47f3;
    --cancel-color : #c0c0c0;
    --cancel-hover : #7e7e7e;
  }

  &.dropdown {
    margin-left: 4px;
  }

  &-drawer {
    display: none;

    @media screen and (max-height: 800px) {
      display: block;
    }

    .menu-content {
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      margin: 0;
      border: 0;
      box-shadow: 0 0 12px 0 rgba(black, 0.4);
      flex-direction: column;
      justify-content: center;
      padding: 4px;
      width: 240px;
      z-index: 1000;
      overflow: auto;
      background-color: var(--bg-color);
      display: none;

      &.show {
        @media screen and (max-height: 800px) {
          display: flex;
          animation: edit-columns-dropdown-show 0.3s forwards;
        }
      }
    }
  }

  &-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(black, 0.5);
    display: none;
    z-index: 999;

    &-show {
      @media screen and (max-height: 800px) {
        display: block;
        animation: edit-columns-overlay-show 0.2s forwards;
      }
    }
  }

  .column-error {
    color: #FE0707;
    text-align: center;
    font-size: 14px;
  }

  .dropdown-menu {
    width: 220px;
    margin-right: 8px;
    margin-top: 4px;

    &.show {
      @media screen and (max-height: 800px) {
        display: none;
      }
    }

  }
  
  &-toggle {
    all: unset;
    box-sizing: border-box;
    cursor: pointer;
    background-color: var(--button-bg);
    width: 28px;
    height: 28px;
    font-weight: bolder;
    font-size: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    box-shadow: 0 1px 6px 0 rgba(black, 0.1);
    color: var(--toggle-color);
    
    [data-theme=dark] & {
      border :1px solid #484d50;
    }
  }

  .sortable-column {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    color: var(--text-color);
    padding: 3px 8px 3px 4px;
    gap: 4px;
    background-color: var(--bg-color);

    @media screen and (max-height: 920px) {
      padding-block: 1px;
    }

    @media screen and (max-height: 800px) {
      padding-block: 4px;
    }

    @media screen and (max-height: 600px) {
      padding-block: 2px;
    }

    .column-name {
      flex: 1;
    }

    input[type=checkbox]:checked {
      background-color: var(--checkbox-bg);
      border-color: var(--checkbox-bg);
    }
  }

  .cancel-save {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin: 8px 0;

    button {
      all: unset;
      box-sizing: border-box;
      cursor: pointer;
      border-radius: 32px;
      font-size: 14px;
      
      &.cancel {
        color: var(--cancel-color);
        padding: 4px;

        &:hover:not(:disabled) {
          color: var(--cancel-hover)
        }
      }
      
      &.save {
        padding: 4px 20px;
        background-color: var(--primary-bg);
        color: white;
        font-weight: bold;
        min-width: 76px;
        display: flex;
        align-items: center;
        justify-content: center;

        &:disabled {
          opacity: 0.7;
        }
      }
    }
  }

  button.reset-columns {
    all: unset;
    box-sizing: border-box;
    cursor: pointer;
    display: block;
    margin: 4px auto;
    color: #469BFF;
    font-style: italic;
    font-size: 14px;

    &:hover:not(:disabled) {
      text-decoration: underline;
    }
  }
}

@keyframes edit-columns-overlay-show {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes edit-columns-dropdown-show {
  from {
    margin-right: -50%;
  }
  to {
    margin-right: 0;
  }
}
.__leads-mode {
  display: flex;
  justify-content: center;
  font-size: 14px;

  --fg-color     : #F1F1F1;
  --bg-color     : white;
  --text-color   : #808080;
  --icon-color   : #B4B4B4;
  --hover-bg     : white;
  --active-bg    : white;
  --active-color : #4C62FB;
  
  [data-theme=dark] & {
    --bg-color     : #212529;
    --fg-color     : #3f4043;
    --text-color   : #ececec;
    --icon-color   : #e1e1e1;
    --hover-bg     : #595959;
    --active-bg    : #2E47F3;
    --active-color : white;
  }

  .container-edge-left {
    background-color: var(--bg-color);
    width: 16px;
    border-top-right-radius: 16px;
    box-shadow: 0 -16px 0 var(--fg-color);
  }

  .container-edge-right {
    background-color: white;
    width: 16px;
    border-top-left-radius: 16px;
    box-shadow: 0 -16px 0 var(--fg-color);

    [data-theme=dark] & {
      background-color: #212529;
    }
  }

  .main-container {
    display: flex;
    background-color: var(--fg-color);
    gap: 8px;
    padding: 6px 8px;

    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;

    > button, > a {
      all: unset;
      box-sizing: border-box;
      cursor: pointer;
      flex: 1;
      text-decoration: none;
      color: var(--text-color);
      display: flex;
      align-items: center;
      gap: 6px;
      justify-content: center;
      border-radius: 8px;
      padding: 4px;
      transition: 0.3s background-color;
      min-width: 100px;

      &.active {
        color: var(--active-color);
        background-color: var(--active-bg);
        font-weight: bold;
        box-shadow: 0 1px 6px 0 rgba(black, 0.1);

        > svg path {
          fill: var(--active-color);
        }
      }

      &:hover:not(.active) {
        background-color: var(--hover-bg);
      }

      > svg {
        width: 14px;
        height: auto;

        path {
          fill: var(--icon-color);
        }
      }
    }
  }
}

.__toast {
  position: fixed;
  z-index: 2000;
  bottom: 20px;
  left: 50%;
  background-color: #4C62FB;
  color: white;
  display: flex;
  font-size: 14px;
  padding: 6px;
  border-radius: 8px;
  align-items: center;
  gap: 8px;
  box-shadow: 0 2px 6px 0 rgba(black, 0.2);
  opacity: 0;
  transform: translate(-50%, 6px);
  transition-duration: 0.3s;
  transition-property: opacity, transform;
  display: none;

  &-enter {
    display: block;

    &-active, &-done {
      display: block;
      opacity: 1;
      transform: translateX(-50%);
    }
  }

  &-exit {
    display: block;
    
    &-active {
      display: block;
    }
    &-done {
      display: none;
    }
  }

  &-text {
    margin-inline: 8px;
  }

  &-action {
    all: unset;
    cursor: pointer;
    box-sizing: border-box;
    text-transform: uppercase;
    font-weight: 800;
    border-radius: 4px;
    padding: 2px 8px;

    &:hover {
      background-color: rgba(white, 0.1);
    }
  }

  &-close {
    all: unset;
    cursor: pointer;
    box-sizing: border-box;
    padding: 2px 8px;
    border-radius: 4px;

    &:hover {
      background-color: rgba(white, 0.1);
    }
  }
}

.__user-avatar {
  display: flex;
  gap: 6px;
  align-items: center;

  &-icon {
    width: 24px;
    height: 24px;
    border-radius: 100%;
    font-size: 11px;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    margin-block: 2px;
    flex-shrink: 0;
    line-height: 0;
  }
}
